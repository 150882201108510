.styledSwimlaneContainer {
    display: flex;
    flex-direction: row;
    height: 100%;
    margin: var(--spacing--lg) var(--spacing--md);
    max-width: 1204px;
    overflow: hidden;
    position: relative;
}

@media screen and (max-width: 1205px) {
    .styledSwimlaneContainer {
        max-width: 800px;
    }
}

@media screen and (max-width: 801px) {
    .styledSwimlaneContainer {
        max-width: 600px;
    }
}
