.styledIconWrapper {
    align-content: center;
    background-color: var(--color-brand--primary);
    border-radius: 100%;
    display: flex;
    height: 40px;
    justify-content: center;
    opacity: 0.63;
    padding: 1px;
    width: 40px;
}

.styledIconWrapper:focus,
.styledIconWrapper:hover {
    background-color: var(--color-brand--hover);
    opacity: 1;
}

.scrollIconWrapper {
    align-content: center;
    border: none;
    display: flex;
    fill: var(--color-white);
    height: 88%;
    justify-content: center;
    overflow: auto;
    position: absolute;
    transition: all ease 0.25s;
    width: 64px;
    z-index: 2;
}

.scrollIconWrapper:focus .styledIconWrapper,
.scrollIconWrapper:hover .styledIconWrapper {
    background-color: var(--color-brand--hover);
    opacity: 1;
}

button.styledLinkButton {
    display: none;
}

.styledSvg {
    align-self: center;
    display: flex;
    justify-self: center;
}

@media (min-width: 60rem) {
    button.styledLinkButton {
        display: block;
    }

    .scrollIconWrapper.right {
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, var(--color-white) 100%);
    }

    .scrollIconWrapper.left {
        background: linear-gradient(to left, rgba(255, 255, 255, 0) 0%, var(--color-white) 100%);
    }
}
