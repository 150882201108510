.productDetailItemSkeleton {
    composes: pulse-background from '../../../../styles/loading-skeletons.css';
    background-color: var(--color-gray--10);
    height: 32px;
    margin: var(--spacing--md) 0;
    width: 200px;
}

.productDetailItemSkeleton.extraSmall {
    height: 14px;
    width: 32px;
}

.productDetailItemSkeleton.small {
    width: 100px;
}

.productDetailItemSkeleton.medium {
    width: 150px;
}

.productDetailItemSkeleton.large {
    width: 320px;
}

@media (min-width: 1204px) {
    .productDetailItemSkeleton {
        margin: 0 0 var(--spacing--md);
    }
}

.detailsContainer {
    margin: var(--spacing--md);
    width: auto;
}

.styledButtonRow {
    display: flex;
    flex-direction: row;
}

.styledButtonSkeleton {
    composes: pulse-background from '../../../../styles/loading-skeletons.css';
    background-color: var(--color-gray--10);
    height: 45px;
    margin: 0 var(--spacing--md) 0 0;
    width: 125px;
}
