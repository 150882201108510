.headerContainer {
    padding-bottom: var(--spacing--sm);
}

.actionButtonContainer {
    display: flex;
    gap: var(--spacing--sm);
    justify-content: flex-end;
    padding-top: var(--spacing--sm);
    width: 100%;
}
