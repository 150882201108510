.form {
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height--md);
}

.radioButtonContainer {
    border: var(--border-width) solid var(--color-white);
    color: var(--color-gray--50);
    cursor: pointer;
    display: block;
    overflow: hidden;
}

.list {
    border: solid 2px transparent;
    border-radius: 10px;
    color: var(--color-gray--50);
    display: grid;
    grid-gap: var(--spacing--sm);
    grid-template-columns: auto 80px;
    padding: var(--spacing--md-lg) var(--spacing--md-sm);
}

.listTitle {
    overflow: hidden;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.radioButtonContainer input {
    opacity: 0;
    position: absolute;
    width: 0;
}

.titleModal {
    margin-bottom: var(--spacing--lg);
}

.radioButtonContainer input[type='radio']:focus:not(:checked) + label {
    border: var(--border-width) solid var(--color-white);
    border-color: var(--color-brand--a11y-primary);
}

.radioButtonContainer input[type='radio'] + label {
    border: var(--border-width) solid var(--color-white);
}

.radioButtonContainer input[type='radio']:hover + label,
.radioButtonContainer input[type='radio']:checked + label {
    background: var(--color-red--10);
    border: var(--border-width) solid var(--color-brand--a11y-primary);
    cursor: pointer;
}

.list svg {
    margin-right: var(--spacing--sm);
}

.addNewListButton {
    display: grid;
    font-size: var(--spacing--md);
    font-weight: var(--font-weight--bold);
    gap: var(--spacing--sm);
    grid-template-columns: 24px auto;
    line-height: var(--line-height--x-lg);
    padding: var(--spacing--md-lg) var(--spacing--md-sm);
    text-align: left;
    width: auto;
}

.addNewListButtonText {
    position: relative;
}

.input label {
    display: none;
}

.addNewListButton:not(:disabled):hover .addNewListButtonText::after,
.addNewListButton:not(:disabled):focus .addNewListButtonText::after {
    background: var(--color-brand--a11y-primary);
    bottom: 0;
    content: '';
    height: 2px;
    left: 0;
    position: absolute;
    width: 100%;
}

.totalItemsList {
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--regular);
    justify-self: end;
    line-height: var(--line-height--x-lg);
}

.doneButton {
    margin-top: var(--spacing--lg);
}

.createEditFormContainerField {
    margin-bottom: var(--spacing--md);
}

.createEditFormContainerField input {
    margin-top: 0;
}

.createEditFormContainerField button {
    align-self: self-start;
}

.errorBox {
    display: flex;
}

.errorBoxMessage {
    color: var(--color-red--30);
    line-height: var(--line-height--lg);
    margin-left: var(--spacing--sm);
}

@media (min-width: 60rem) {
    .list,
    .addNewListButton {
        padding: var(--spacing--lg) var(--spacing--md);
    }
}
