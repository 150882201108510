.availableTextHeaderContainer {
    padding-left: var(--spacing--x-lg);
}

.availableTextHeaderContainer svg,
.availableTextContainer svg {
    margin-right: var(--spacing--x-sm);
}

.unavailableText {
    color: var(--color-gray--40);
}

.greenAvailableText {
    color: var(--color-green--30);
    font-weight: var(--font-weight--semi-bold);
}

.errorText {
    align-items: center;
    color: var(--color-red--30);
    display: flex;
    margin-top: var(--spacing--sm);
}

.errorIcon {
    margin-right: var(--spacing--sm);
}

.availableTextContainer {
    display: flex;
    flex-direction: column;
}
