.styledFixedContainer {
    background-color: var(--color-white);
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    z-index: 100;
}

.styledRelativeContainer {
    position: relative;
}

.styledFixedContainer.background,
.styledRelativeContainer.background {
    background: #f4f3f3;
}

.styledSelectionCheckmark {
    margin-left: var(--spacing--x-sm);
}
