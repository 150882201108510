.styledMobileInfoContainer {
    display: flex;
    flex-direction: column;
    margin: var(--spacing--lg) var(--spacing--md);
}

.styledProductDetailsInformation {
    composes: pulse-background from './../../../../styles/loading-skeletons.css';
    background-color: var(--color-gray--10);
    display: none;
}

@media screen and (min-width: 601px) {
    .styledMobileInfoContainer {
        display: none;
    }

    .styledProductDetailsInformation {
        display: flex;
        flex-direction: column;
        height: 400px;
        margin: 56px var(--spacing--md);
    }
}
