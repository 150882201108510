@keyframes pulseBackground {
    0% {
        opacity: 100%;
    }

    50% {
        opacity: 30%;
    }

    100% {
        opacity: 100%;
    }
}

.pulse-background {
    animation: pulseBackground 1s infinite ease-in-out;
}

@keyframes pulseBorder {
    0% {
        opacity: 100%;
    }

    50% {
        opacity: 30%;
    }

    100% {
        opacity: 100%;
    }
}

.pulse-border {
    animation: pulseBorder 1s infinite ease-in-out;
}

@keyframes pulseFill {
    0% {
        opacity: 100%;
    }

    50% {
        opacity: 30%;
    }

    100% {
        opacity: 100%;
    }
}

.pulse-fill {
    animation: pulseFill 1s infinite ease-in-out;
}
