.customLinkButton {
    border-bottom: var(--border-width) solid var(--color-brand--a11y-primary);
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--medium);
    line-height: var(--line-height--x-lg);
    text-decoration: none;
}

@media (min-width: 48rem) {
    .customLinkButton {
        font-size: var(--font-size--23);
        line-height: var(--line-height--md);
    }
}
