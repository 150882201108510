.createEditList {
    display: block;
}

.formContainerField {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--sm);
    margin-bottom: var(--spacing--x-lg);
    position: relative;
    width: 100%;
}

.formContainerField button {
    align-self: self-end;
}

.errorMessage {
    bottom: 0;
    left: 0;
    position: absolute;
    width: 100%;
}

.createEditList [class*='TextInputContainer'] {
    flex-grow: 1;
    width: auto;
}

.createEditFormContainerSuccess {
    color: var(--color-green--30);
    font-size: var(--font-size--12);
    font-weight: var(--font-weight--medium);
    margin: 0 0 0 var(--spacing--sm);
}

.createEditFormContainerSuccess svg {
    margin-right: var(--spacing--sm);
}

.inviteCollaboratorFormContainer {
    border-top: solid var(--border-width) var(--color-gray--20);
    padding: var(--spacing--lg) 0 0 0;
}

.invitedCollaboratorsContainer {
    border-top: solid var(--border-width) var(--color-gray--20);
    padding: var(--spacing--lg) 0 0 0;
}

.invitedCollaboratorsList {
    height: 88px;
    margin: var(--spacing--sm) 0 10px 0;
    overflow-x: hidden;
    overflow-y: auto;
    padding: 0 10px 0 0;
}

.quickAddCollaboratorsContainer {
    border-top: solid var(--border-width) var(--color-gray--20);
    margin: var(--spacing--lg) 0 var(--spacing--lg) 0;
    padding: var(--spacing--lg) 0 0 0;
}

.newList,
.newList input::placeholder {
    color: var(--color-gray--30);
}

@media (min-width: 60rem) {
    .formContainerField {
        flex-direction: row;
    }

    .invitedCollaboratorsList {
        margin-top: var(--spacing--lg);
    }
}
