/* stylelint-disable selector-class-pattern */

.input input {
    background: var(--color-gray--10);
    border: solid var(--border-width) var(--color-gray--10);
    border-radius: 84px;
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--medium);
    padding: var(--spacing--sm-md) var(--spacing--md);
}

.input input::placeholder {
    font-weight: var(--font-weight--regular);
}

.input input:focus {
    border-color: var(--color-gray--40);
    outline: none;
}

.input.inputSuccess input {
    background: var(--color-green--10);
    border: solid var(--border-width) var(--color-green--30);
}

.inputErrorText {
    color: var(--color-red--30);
    font-size: var(--font-size--12);
    font-weight: var(--font-weight--medium);
    margin: 0 0 0 var(--spacing--sm);
}

.inputError input {
    background: var(--color-red--10);
    border: solid var(--border-width) var(--color-red--30);
    color: var(--color-red--30);
    margin-bottom: 0;
}

.input label {
    color: var(--color-gray--60);
    font-weight: var(--font-weight--bold);
    margin-bottom: var(--spacing--md);
}

.input label [class*='labelText'] {
    font-size: var(--font-size--24);
}

.doneButton {
    display: block;
    margin: 0 auto;
    max-width: 312px;
    width: 100%;
}
