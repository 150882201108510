.time {
    align-items: baseline;
    display: flex;
    flex-shrink: 0;
    grid-column-start: 1;
}

.time > strong {
    font-weight: bold;
}

.cutoffSelected {
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--semi-bold);
}

.cutoff {
    color: var(--color-gray--50);
    font-weight: var(--font-weight--light);
}

.warningIcon {
    margin: 0 var(--spacing--x-sm) -1px;
}
