section.modalContainer {
    animation: none;
    border-radius: 20px;
    left: 0;
    margin: auto;
    max-height: 90%;
    max-width: 640px;
    overflow-y: auto;
    padding: var(--spacing--xxx-lg-sm) var(--spacing--lg);
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    width: 90%;
}

.modalButton {
    cursor: pointer;
}

.modalButton svg {
    pointer-events: none;
}

.modalButton:focus {
    outline: var(--border-width) solid var(--color-gray--40);
}

.modalContainer p {
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--regular);
    line-height: var(--line-height--x-lg);
}

.modalContainer h2 {
    font-size: var(--font-size--23);
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height--md);
    margin-bottom: var(--spacing--sm);
}

.modalContainer.modalContainerEdit h2 {
    color: var(--color-gray--60);
    font-size: var(--font-size--13);
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height--x-lg);
}

.modalContainer.invitedContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--lg);
}

.subtitleInvitedModal {
    font-size: var(--font-size--14);
    margin-top: calc(var(--spacing--lg) * -1);
}

.listInvitationContainer {
    display: flex;
    flex-direction: column;
    gap: var(--spacing--lg);
    height: auto;
    max-height: 280px;
    overflow-y: hidden;
    padding: var(--spacing--x-sm-sm);
}

.listInvitationContainerMultiple {
    overflow-y: scroll;
}

.invitedListContainer {
    align-items: flex-start;
    align-self: flex-start;
    display: flex;
    flex-direction: column;
    gap: var(--spacing--md);
    justify-content: space-between;
    width: 100%;
}

.invitedListName {
    color: var(--color-gray--50);
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height);
    overflow: hidden;
    text-align: left;
    text-overflow: ellipsis;
    text-wrap: nowrap;
}

.invitedContainerBtn {
    align-items: center;
    align-self: stretch;
    display: flex;
    gap: var(--spacing--md);
    justify-content: center;
}

.invitedBtn {
    display: flex;
    flex: 1 0 0;
    padding: var(--spacing--sm) var(--spacing--md);
}

.btnDecline:hover {
    background-color: var(--color-gray--10);
    color: var(--color-brand--a11y-primary);
}

.btnAccept:hover {
    background-color: var(--color-brand--hover);
}

.invitedBtn span {
    align-content: center;
    align-items: center;
    display: flex;
    gap: var(--spacing--sm);
    justify-content: center;
    width: 100%;
}

.invitedStatusMessage {
    align-items: center;
    align-self: stretch;
    color: var(--color-gray--60);
    display: flex;
    flex: 1 0 0;
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--medium);
    gap: var(--spacing--x-sm);
}

.invitedContainerBtn .invitedStatusMessage {
    align-self: flex-start;
    justify-content: flex-start;
}

.textHeader {
    color: var(--color-gray--60);
    font-size: var(--font-size--20);
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height--md);
}

.textHeaderSmall {
    color: var(--color-gray--60);
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--bold);
    line-height: var(--line-height--md);
}

.textCopy {
    color: var(--color-gray--60);
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--regular);
    line-height: var(--line-height--x-lg);
}

.textCopySemibold {
    color: var(--color-gray--60);
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--regular);
    line-height: var(--line-height--x-lg);
}

@media (min-width: 48rem) {
    section.modalContainer {
        padding: var(--spacing--xxx-lg-sm) var(--spacing--x-lg);
    }

    .modalContainer.invitedContainer {
        max-width: 483px;
    }

    .modalContainer p {
        font-size: var(--font-size--23);
        line-height: var(--line-height--md);
    }

    .listInvitationContainer {
        height: auto;
        max-height: 165px;
    }

    .invitedListContainer {
        align-items: center;
        flex-direction: row;
    }

    .subtitleInvitedModal {
        font-size: var(--font-size--23);
        margin-top: 0;
    }
}

@media (min-width: 60rem) {
    section.modalContainer {
        padding: var(--spacing--xxx-lg-sm);
    }

    .modalContainer.invitedContainer {
        gap: var(--spacing--x-lg);
        max-width: 591px;
    }

    .modalContainer.invitedContainer,
    .modalContainer.invitedContainer h2 {
        text-align: center;
    }

    .modalContainer.invitedContainer h2 {
        font-size: var(--font-size--36);
    }

    .textHeader {
        font-size: var(--font-size--23);
    }

    .textCopySemibold {
        font-weight: var(--font-weight--semi-bold);
    }
}
