.imageAndDetailsContainer {
    display: flex;
    flex-direction: column;
    margin: var(--spacing--sm) 0 0 20px;
    width: auto;
}

@media screen and (min-width: 601px) {
    .imageAndDetailsContainer {
        flex-direction: row;
    }
}

.imageContainer {
    align-self: flex-start;
    max-width: 409px;
    min-width: 409px;
}

.imageSkeleton {
    composes: pulse-background from './../../../../styles/loading-skeletons.css';
    align-self: center;
    background-color: var(--color-gray--10);
    display: flex;
    flex: 0 0 400px;
    height: 400px;
    margin: var(--spacing--lg) 12px 0;
    max-width: 400px;
}

.productDetailsContainer {
    margin: var(--spacing--lg) 0 0;
    max-width: 300px;
    overflow-x: hidden;
}

.styledContentContainer {
    margin: 0 auto;
    width: 1204px;
}

.styledBreadcrumbLink {
    composes: pulse-background from './../../../../styles/loading-skeletons.css';
    background-color: var(--color-gray--10);
    display: flex;
    flex-flow: row wrap;
    height: 12px;
    margin-left: 20px;
    margin-top: 25px;
    padding-right: 20px;
    width: 320px;
}

@media screen and (min-width: 1001px) {
    .imageContainer {
        align-self: center;
        max-width: 488px;
        min-width: 488px;
    }

    .imageSkeleton,
    .productDetailsContainer {
        margin: var(--spacing--lg) var(--spacing--lg) 0;
    }

    .styledBreadcrumbLink {
        margin-left: 60px;
    }
}

@media screen and (max-width: 1205px) {
    .styledContentContainer {
        width: 1004px;
    }
}

@media screen and (max-width: 1001px) {
    .styledContentContainer {
        margin: 0;
        width: auto;
    }
}
