.homePageSwimLaneLoading {
    margin-top: var(--spacing--x-lg);
}

.styledHeader {
    font-size: var(--font-size--23);
    font-weight: var(--font-weight--extra-bold);
    line-height: var(--line-height--md);
}

.skeletonHeaderText {
    composes: pulse-background from '../../../../styles/loading-skeletons.css';
    background-color: var(--color-gray--10);
    height: 20px;
    width: 100px;
}

.swimLaneHeader {
    align-items: center;
    display: flex;
    justify-content: space-between;
}

.swimLaneWrapper {
    composes: _ao-max-swimlane-width from '../../../../styles/style-constants.css';
    margin-top: var(--spacing--x-lg);
    max-width: var(--ao-max-swimlane-width);
    padding: 0 var(--spacing--md);
    width: 100%;
}

@media only screen and (min-width: 1144px) {
    .swimLaneWrapper {
        padding-left: 0;
        padding-right: 0;
    }
}

@media (min-width: 60rem) {
    .styledHeader {
        font-size: var(--font-size--26);
    }
}

@media (min-width: 86.25rem) {
    .swimLaneWrapper {
        padding-left: 0;
        padding-right: 0;
    }
}
