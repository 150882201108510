.wrapper {
    display: flex;
    flex-shrink: 0;
}

.wrapper.fullWidth {
    width: 100%;
}

.wrapper:has(> div:empty) {
    display: none;
}
