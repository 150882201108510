._ao-max-mobile-width {
    --ao-max-mobile-width: 600px;
}

._ao-max-swimlane-width {
    --ao-max-swimlane-width: 1144px;
}

._ao-min-web-size {
    composes: _ao-max-mobile-width;
    --ao-min-web-size: calc(var(--ao-max-mobile-width) + 1px);
}

._fuel-saver-background-color {
    --fuel-saver-background-color: #232325;
}

._image-size {
    --image-size: 100px;
}

._large-ad-cutoff {
    --large-ad-cutoff: 1066px;
}

._light-border {
    --light-border: var(--border-width) solid #ddd;
}

._selected-background-color {
    --selected-background-color: #edf1ed;
}

._site-max-width {
    --site-max-width: 1200px;
}

._site-padding {
    --site-padding: 20px;
}

._transition-duration {
    --transition-duration: 0.35s;
}

.alert-text {
    align-items: center;
    border: var(--border-width) solid transparent;
    border-radius: var(--border-radius);
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    margin-bottom: 20px;
    padding: 15px;
    width: 100%;
}

.message-group {
    align-items: center;
    display: flex;
    flex-wrap: nowrap;
    justify-content: center;
    margin-bottom: 0;
}

.page-content {
    composes: _site-padding;
    flex: 1 0 auto;
    max-width: 1200px;
    padding: 0 var(--site-padding);
}

.section-loader {
    height: 100%;
    min-height: 300px;
    position: relative;
    width: 100%;
}

.hidden {
    display: none;
}
