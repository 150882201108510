.globalNavigation {
    margin-top: 114px;
}

.fulfillment,
.defaultHeader {
    margin-top: 116px;
}

.skipNavigation {
    display: block;
    left: 0;
    opacity: 0;
    position: absolute;
    top: 0;
}

.skipNavigation:focus {
    background-color: var(--color-brand--primary);
    border-radius: var(--spacing--x-sm);
    box-shadow: none;
    color: var(--color-white);
    font-size: var(--font-size--14);
    font-weight: var(--font-weight--semi-bold);
    opacity: 1;
    padding: var(--spacing--sm);
    z-index: 9999;
}

@media (min-width: 48rem) {
    .globalNavigation {
        margin-top: 122px;
    }
}

@media (min-width: 60rem) {
    .globalNavigation {
        margin-top: 139px;
    }
}

@media (min-width: 90rem) {
    .globalNavigation {
        margin-top: 138px;
    }
}

/* Leaving this as pixels until nav changes to design tokens. Will cause CLS issues otherwise */
@media (max-width: 991px) {
    .fulfillment {
        margin-top: 80px;
    }
}

/* Leaving this as pixels until nav changes to design tokens. Will cause CLS issues otherwise */
@media (max-width: 1183px) {
    .defaultHeader {
        margin-top: 60px;
    }
}
