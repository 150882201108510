.promoContainer {
    align-items: center;
    background-color: #f9f9f9;
    border-bottom: var(--border-width) solid var(--color-gray--10);
    border-right: var(--border-width) solid var(--color-gray--10);
    display: flex;
    flex-direction: column;
    flex-grow: 0;
    justify-content: space-between;
    min-height: 100%;
    transition: all 0.15s ease;
}

.promoContainerOld {
    width: 245px;
}

.promoContainerNew {
    width: 272px;
}

.textContainer {
    padding: var(--spacing--md);
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
    .textContainer {
        width: 250px;
    }
}

.imageAndText {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
}

.headingText {
    font-size: var(--font-size--16);
    font-weight: var(--font-weight--semi-bold);
    text-align: left;
    width: 100%;
}

@media screen and (max-width: 499px) {
    .headingText {
        font-size: var(--font-size--14);
    }

    .promoContainerNew {
        width: 236px;
    }

    .textContainer {
        padding-top: 6px;
    }
}

.loadingContainer {
    margin: var(--spacing--sm);
    min-height: 400px;
}

.loadingImage {
    composes: pulse-background from 'client/styles/loading-skeletons.css';
    background-color: var(--color-gray--20);
    height: 244px;
    width: 244px;
}
